import React from "react";
import config from "../../../config";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="content has-text-centered">
                    <p>{config.copyright}</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
