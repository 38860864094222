import React, { Component } from "react";
import Helmet from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/sass/styles.sass";
import config from "../../../config";
import NavBar from "../NavBar";
import Footer from "../Footer";

class Layout extends Component {
    constructor(props) {
        super(props);
        const { isSimpleFooter } = props;
        this.state = { isActive: false, isSimpleFooter };
        this.toggleNavbar = this.toggleNavbar.bind(this);
    }

    toggleNavbar() {
        this.setState({ isActive: !this.state.isActive });
    }

    render() {
        const footer = this.state.isSimpleFooter ? <div /> : <Footer />;
        return (
            <div id="layout-wrapper">
                <Helmet>
                    <title>{config.siteTitle}</title>
                    <meta name="description" content={config.siteDescription} />
                </Helmet>
                <NavBar
                    isActive={this.state.isActive}
                    toggleNavbar={() => this.toggleNavbar()}
                />
                <div id="content-wrapper">{this.props.children}</div>
                {footer}
            </div>
        );
    }
}

export default Layout;
